.navigation {
  width: 100%;
}

.navigation__list {
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.navigation__list-item {
  flex: 1;
}

.navigation__list-item-link {
  color: #fff;
  background-color: #000;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-size: 1rem;
  text-decoration: none;
  transition: background-color .2s ease-in-out;
  display: flex;
}

.navigation__list-item-link[data-is-active="true"] {
  color: #000;
  pointer-events: none;
  background: #f0f0f0;
}

.navigation__list-item-link:hover, .navigation__list-item-link:focus {
  background-color: #282828;
}

@media (min-width: 700px) {
  .navigation__list {
    flex-direction: row;
  }

  .navigation__list-item-link {
    padding: 2rem;
    font-size: 2rem;
  }
}

/*# sourceMappingURL=index.4489227c.css.map */
